<template>
  <div>
    <el-row>
      <el-table
          ref="multipleTable"
          :data="tableData.records"
          tooltip-effect="dark"
          style="width: 100%">
        <el-table-column
            prop="username"
            label="姓名"
            width="100">
        </el-table-column>

        <el-table-column
            prop="userImg"
            label="头像"
            width="120">
          <template slot-scope="scope">
            <el-avatar :src=scope.row.userImg></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
            prop="level"
            label="等级"
            width="120">
          <template slot-scope="scope">
            <el-tag effect="dark" type="danger" v-if="scope.row.level===0">
              {{ getLevel(scope.row.level) }}
            </el-tag>
            <el-tag effect="dark" type="primary" v-else>
              {{ getLevel(scope.row.level) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="userTelephone"
            label="电话号码"
            width="120">
        </el-table-column>
        <el-table-column
            prop="userEmail"
            label="邮箱"
            width="180">
        </el-table-column>
        <el-table-column
            sortable
            prop="gmtCreate"
            label="加入时间"
            width="200">
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="tableData"
          background
          :current-page="tableData.current"
          :page-size="tableData.size"
          @current-change="getPass"
          layout="prev, pager, next"
          :total="tableData.total">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import {selectCrowdInfoById} from "@/api/crowd";

export default {
  name: "member-info",
  data() {
    return {
      tableData: [],
      id: "",
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getPass(1);
  },
  methods: {
    getPass(number) {
      selectCrowdInfoById(this.id, number, 2).then(result => {
        this.tableData = result.data.crowd;
      }).catch(error => {
        this.$message(error.message)
      })
    },
    getLevel(item) {
      if (item === 0) {
        return "群主"
      } else if (item === 1) {
        return "班助"
      } else {
        return "群众"
      }
    },
  }
}
</script>

<style scoped>

</style>